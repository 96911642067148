.errorPage{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3CA3DB;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    .content{
        max-width: 500px;
        padding: 10px;
        .title{
            position: relative;
            .main{
                color: #FBD136;
                font-size: 10em;
                font-weight: bold;
            }
            .overlay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.7em;
                text-transform: uppercase;
                color: black;
            }
        }
        .subtitle{
            font-size: 1.7em;
            margin-bottom: 10px;
        }
        p{
            margin: 0 0 80px 0;
        }
    }
    @media( min-width: 500px){
        &:before,
        &:after{
            content: " ";
            background-image: url('https://instance11.admin.grandus.sk/files/attachment/2020-09/223/943843/404-mestskaveza.png');
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: block;
            width: 200px;
            height: 400px;
            position: absolute;
            bottom: 0;
            left: 0;
            mix-blend-mode: luminosity;
            z-index: 1;
        }
        &:after{
            left: auto;
            right: 0;
            transform: rotate(180deg);
            bottom: auto;
            top:0;
        }
        .content{
            .title{
                .main{
                    font-size: 12em;
                }
            }
        }
    }
    @media( min-width: 800px){
        &:before,
        &:after{
            width: 300px;
            height: 600px;
        }
        .content{
            .title{
                .main{
                    font-size: 15em;
                }
            }
        }
    }
    @media( min-width: 1200px){
        &:before,
        &:after{
            width: 400px;
            height: 800px;
        }
        .content{
            .title{
                .main{
                    font-size: 20em;
                }
            }
        }
    }
    @media( min-width: 1400px){
        &:before,
        &:after{
            width: 500px;
            height: 1000px;
        }
    }
}